"use client";

import Image from "next/image";
import Link from "next/link";
import React, { useCallback, useEffect, useState } from "react";

import { search as executeSearch } from "@/actions/search";
import SearchInput from "@/components/Menu/SearchInput";
import { SEARCH_STORAGE } from "@/components/Search/last-search";
import { useDebounce } from "@/hook/debounce";
import { useSearch } from "@/lib/contexts/search-context";
import { SEARCH_MODAL, useSideModal } from "@/lib/contexts/sidemodal-context";
import { useStorage } from "@/lib/hooks/use-storage";

export default function NotFoundPage() {
  const storage = useStorage(SEARCH_STORAGE);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { setSearchResult, setSearchQuery: setSearchQueryModal } = useSearch();
  const { setModal } = useSideModal();

  useEffect(() => {
    if (searchQuery.length < 3) {
      return;
    }

    const searchResult = async () => {
      executeSearch({ query: searchQuery, type: "suggest" }).then((res) => {
        setSearchResult(res.body);
      });
    };
    searchResult().then((r) => r);
  }, [setSearchResult, searchQuery]);

  const debouncedSearchQuery = useDebounce(() => {
    setSearchQuery(search);
  }, 500);

  const debouncedSearch = useCallback(
    (valueSearch: string) => {
      setSearch(valueSearch);
      if (valueSearch.length < 3) {
        return;
      }
      debouncedSearchQuery();
    },
    [debouncedSearchQuery],
  );

  return (
    <div className="text-center">
      <div className="bg-[#575757] text-white pt-[65px] pb-[70px] mt-[20px] max-w-[1180px] inline-block">
        <h1 className="text-2xl font-bold mt-[20px] mb-[10px] px-1">Verzappt und zugen&auml;ht!</h1>
        <h2 className="text-sm mt-[28px] mb-[10px] font-light px-1">Diese Seite ist leider nicht bei uns im Programm.</h2>
        <p className="text-xs mt-[56px] mb-[10px] font-light px-1">
          Schauen Sie nicht in die R&ouml;hre - entdecken Sie lieber die Lieblingsmarken unserer Kunden.
        </p>
        <ul className="mt-[24px] mb-[10px]">
          <li className="bg-white inline-block mr-[3px] lg:mr-[12px] mb-[20px] w-[218px]">
            <Link href="/b/monaco-blue" className="block">
              <Image src="/img/brands/monacoblue.webp" className="inline-block max-h-[75px]" alt="Monaco Blue" width="117" height="75" />
            </Link>
            <div className="bg-[#333333] text-xs font-light leading-5">MONACO blue</div>
          </li>
          <li className="bg-white inline-block mr-[3px] lg:mr-[12px] mb-[20px] w-[218px]">
            <Link href="/b/ricarda-m" className="block">
              <Image src="/img/brands/ricardam.webp" className="inline-block max-h-[75px]" alt="Ricarda M." width="117" height="75" />
            </Link>
            <div className="bg-[#333333] text-xs font-light leading-5">Ricarda M.</div>
          </li>
          <li className="bg-white inline-block mr-[3px] lg:mr-[12px] mb-[20px] w-[218px]">
            <Link href="/b/sarah-kern" className="block">
              <Image src="/img/brands/170703_SarahKern_Logo_Farbprfil.webp" className="inline-block max-h-[75px]" alt="Sarah Kern" width="117" height="75" />
            </Link>
            <div className="bg-[#333333] text-xs font-light leading-5">Sarah Kern</div>
          </li>
          <li className="bg-white inline-block mr-[3px] lg:mr-[12px] mb-[20px] w-[218px]">
            <Link href="/b/christian-materne" className="block">
              <Image src="/img/brands/170510_Materne_Logo_Bunt_CM.webp" className="inline-block max-h-[75px]" alt="Chritian Materne" width="117" height="75" />
            </Link>
            <div className="bg-[#333333] text-xs font-light leading-5">Christian Materne</div>
          </li>
          <li className="bg-white inline-block mr-[3px] lg:mr-[0] mb-[20px] w-[218px]">
            <Link href="/b/higloss" className="block">
              <Image src="/img/brands/higloss.webp" className="inline-block max-h-[75px]" alt="HiGloss" width="117" height="75" />
            </Link>
            <div className="bg-[#333333] text-xs font-light leading-5">HiGloss</div>
          </li>
          <li className="bg-white inline-block mr-[3px] lg:mr-[12px] mb-[20px] w-[218px]">
            <Link href="/b/ogimapro" className="block">
              <Image src="/img/brands/ogimapro.webp" className="inline-block max-h-[75px]" alt="Ogima Pro" width="117" height="75" />
            </Link>
            <div className="bg-[#333333] text-xs font-light leading-5">Ogima Pro</div>
          </li>
          <li className="bg-white inline-block mr-[3px] lg:mr-[12px] mb-[20px] w-[218px]">
            <Link href="/b/wenko" className="block">
              <Image src="/img/brands/190806_220x168px_Wenko.webp" className="inline-block max-h-[75px]" alt="Wenko" width="117" height="75" />
            </Link>
            <div className="bg-[#333333] text-xs font-light leading-5">Wenko</div>
          </li>
          <li className="bg-white inline-block mr-[3px] lg:mr-[12px] mb-[20px] w-[218px]">
            <Link href="/b/gourmetmaxx" className="block">
              <Image src="/img/brands/Sascha_Heyna_380x244_Header.webp" className="inline-block max-h-[75px]" alt="Sascha Heyna" width="117" height="75" />
            </Link>
            <div className="bg-[#333333] text-xs font-light leading-5">Sascha Heyna</div>
          </li>

          <li className="bg-white inline-block mr-[3px] lg:mr-[12px] mb-[20px] w-[218px]">
            <Link href="/b/gesundheitscheck" className="block">
              <Image
                src="/img/brands/Logo_220x168_Gesundheitscheck_SW.webp"
                className="inline-block max-h-[75px]"
                alt="Gesundheitscheck"
                width="117"
                height="75"
              />
            </Link>
            <div className="bg-[#333333] text-xs font-light leading-5">Gesundheitscheck</div>
          </li>
          <li className="bg-white inline-block mr-[3px] lg:mr-[0] mb-[20px] w-[218px]">
            <Link href="/b/sebastian-boehm" className="block">
              <Image src="/img/brands/Logo_220x168_SebastianBoehm_SW.webp" className="inline-block max-h-[75px]" alt="Sebastian Böhm" width="117" height="75" />
            </Link>
            <div className="bg-[#333333] text-xs font-light leading-5">Sebastian Böhm</div>
          </li>
        </ul>
        <h2 className="mt-[45px] mb-[10px] text-sm font-light px-1">Sie m&ouml;chten direkt zu Ihrem Wunschprodukt umschalten?</h2>
        <p className="text-xs font-light mt-[19px] mb-[10px] px-1">Dann nutzen Sie unsere Suche.</p>
        <div className="mt-[17px] mb-[20px] text-black flex justify-end lg:ml-[-90px]">
          <SearchInput
            placeholder=""
            onChange={(event) => debouncedSearch(event.target.value)}
            onKeyDown={(event) => {
              if (event.code === "Enter") {
                storage.add(event.currentTarget.value, event.currentTarget.value, { cappedSize: 5, onDuplicateName: "readd" });
                setSearchQueryModal(search);
                setModal({ name: SEARCH_MODAL, isShow: true });
              }
            }}
            onReset={() => debouncedSearch("")}
            value={search}
          />
        </div>

        <Image alt="" className="inline-block" src="/img/brands/kk-c21-404-Fernseher-animation-orange.gif" width="490" height="560" unoptimized={true} />
      </div>
    </div>
  );
}
